
function ActionType() {
    return {
        DataA: 'DataA',
        Login: 'Login',
        getUserInfo: 'getUserInfo',
        getTokenHeaderColor: 'getTokenHeaderColor',
        getGroupList: 'getGroupList',
        getBookLists: 'getBookLists',
        getChapterList: 'getChapterList',
        getArticleLists: 'getArticleLists',
        deleteArticle: 'deleteArticle',
        getDeteils: 'getDeteils',
        patchArticle: 'patchArticle',
        postWord: 'postWord',
        deleteWord: 'deleteWord',
        getIndexImage: 'getIndexImage',
        postWordQuestion: 'postWordQuestion',
        postWordQuestionAnswer: 'postWordQuestionAnswer',
        postAddData: 'postAddData',
        getPromptList: 'getPromptList',
        postPromptList: 'postPromptList',
        deleteNewArticle: 'deleteNewArticle',
        onGetNewArticle: 'onGetNewArticle',
        deleteChoices: 'deleteChoices',
        deleteQuestion: 'deleteQuestion',
        deleteChapter: 'deleteChapter',
        deleteGroup: 'deleteGroup',
        deleteBook: 'deleteBook',
        deletePormpt: 'deletePormpt',
        deleteParam: 'deleteParam',
        postParam: 'postParam',
        addFiles: 'addFiles',
        addVideoFiles: 'addVideoFiles',
        getVideoFiles: 'getVideoFiles',
        deleteVideoFiles: 'deleteVideoFiles',
        getWordData: 'getWordData',
        postNewArticle: 'postNewArticle',
        editData: 'editData',
        editWordQuestion: 'editWordQuestion',
        editWordQuestionAnswer: 'editWordQuestionAnswer',
        editWord: 'editWord',
        editAddData: 'editAddData',
        postChatGPT: 'postChatGPT',
        postChatGPTTO: 'postChatGPTTO',
        getParamList: 'getParamList',
        addExample: 'addExample',
        getExample: 'getExample',
        deleteExample: 'deleteExample',
        pathExample: 'pathExample',
        patchNewArticle: 'patchNewArticle',
        copyBook: 'copyBook',
        patchChapterType: 'patchChapterType',
        getSummary: 'getSummary',
        getChoices: 'getChoices',
        postChoices: 'postChoices',
        postSummary: 'postSummary',
        patchSummary: 'patchSummary',
        deleteSummary: 'deleteSummary',
        postValue: 'postValue',
        getStudentGroup: 'getStudentGroup',
        getStudentStore: 'getStudentStore',
        getStudentMyBook: 'getStudentMyBook',
        patchStorePay: 'patchStorePay',
        getQuestion: 'getQuestion',
        postTestSheet: 'postTestSheet',
        getBookDetails: 'getBookDetails',
        getDealerUser: 'getDealerUser',
        getDealerCode: 'getDealerCode',
        getMyGroup: 'getMyGroup',
        getStudentBookDetails: 'getStudentBookDetails',
        getChoicesTo: 'getChoicesTo',
        postChoicesTo: 'postChoicesTo',
        deleteChoicesTo: 'deleteChoicesTo',
        PostAnswer: 'PostAnswer',
        getReadingQuestion: 'getReadingQuestion',
        postReadingQuestion: 'postReadingQuestion',
        deleteReadingQuestion: 'deleteReadingQuestion',
        getSearch: 'getSearch',
        getPassageQuestion: 'getPassageQuestion',
        getStudentTutorial: 'getStudentTutorial',
        getErrorQuestion: 'getErrorQuestion',
        getWordQuestion: 'getWordQuestion',
        getStudentWord: 'getStudentWord',
        postQuestionAnswer: 'postQuestionAnswer',
        getQuestionAnswer: 'getQuestionAnswer',
        getSearchChapragraph: 'getSearchChapragraph',
        getChapterWord: 'getChapterWord',
        getSearchWord: 'getSearchWord',
        getEditWordList: 'getEditWordList',
        getWordPrompt: 'getWordPrompt',
        getWrongWord: 'getWrongWord',
        deleteWrongWord: 'deleteWrongWord',
        getErrorWordQuestion: 'getErrorWordQuestion',
        patchWordInpspector: 'patchWordInpspector',
        patchWord: 'patchWord',
        patchQuestionInpspector: 'patchQuestionInpspector',
        getTopicList: 'getTopicList',
        postTopicData: 'postTopicData',
        deleteTopic: 'deleteTopic',
        patchTopicData: 'patchTopicData',
        getOrderList: 'getOrderList',
        postTrial: 'postTrial',
        patchUserInfo: 'patchUserInfo',
        getStudentStoreDetails: 'getStudentStoreDetails',
        getActivityList: 'getActivityList',
        getPicture: 'getPicture',
        getPayResult: 'getPayResult',
        patchGenerate: 'patchGenerate',
        patchGenerateArticle: 'patchGenerateArticle',
        getStatis: 'getStatis',
        getChoicesList: 'getChoicesList',
        postChoicesList: 'postChoicesList',
        deleteChoicesList: 'deleteChoicesList',
        getBookLabel: 'getBookLabel',
        deleteBookLabel: 'deleteBookLabel',
        postBookLabel: 'postBookLabel',
        getGroupLabel: 'getGroupLabel',
        getSearchGroupList: 'getSearchGroupList',
        getGroupGradeLabel: 'getGroupGradeLabel',
        deleteBookGradeLabel: 'deleteBookGradeLabel',
        postBookGradeLabel: 'postBookGradeLabel',
        getGroupTargetLabel: 'getGroupTargetLabel',
        getShopingLabel: 'getShopingLabel',
        getShopingLabel6: 'getShopingLabel6',
        getShopingLabel7: 'getShopingLabel7',
        getShopingLabel8: 'getShopingLabel8',
        getBoxList: 'getBoxList',
        patchChoiceList: 'patchChoiceList',
        getBookFile: 'getBookFile',
        deteleBookFile: 'deteleBookFile',
        getSearchSystemWord: 'getSearchSystemWord',
        patchBookOverView: 'patchBookOverView',
        getIntegral: 'getIntegral',
        getDealerTeacher: 'getDealerTeacher',
        postDealerTeacher: 'postDealerTeacher',
        deleteDealerTeacher: 'deleteDealerTeacher',
        patchDealerTeacher: 'patchDealerTeacher',
        getTeacherClass: 'getTeacherClass',
        postTeacherClass: 'postTeacherClass',
        deleteTeacherClass: 'deleteTeacherClass',
        patchTeacherClass: 'patchTeacherClass',
        getClassStudent: 'getClassStudent',
        postClassStudent: 'postClassStudent',
        deleteClassStudent: 'deleteClassStudent',
        getStatusStudent: 'getStatusStudent',
        getStoreDetails: 'getStoreDetails',
        post_vip_user_add_box: 'post_vip_user_add_box',
        getVipSetting: 'getVipSetting',
        PostKnowWords: 'PostKnowWords'
    }

}
export default ActionType