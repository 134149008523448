
import Styles from './index.module.css'
import React, {
  useState,
} from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Utils, { MiniLogo } from "../../Utils/Config";
import { Form, Input, Button, Radio, Modal } from "antd";
import { Link } from "react-router-dom";
import ActionType from "../../Store/actionType";
const MLogin = () => {
  const [radioValue, setRadioValue] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [formValue, setFormValue] = useState(null)
  // localStorage.setItem("spikeToken", spikeToken);
  // 登录表单提交
  const onFinish = (values) => {
    const isRadio = localStorage.getItem('isRadio')
    if (radioValue || isRadio) {
      if (values || formValue) Utils.post({
        url: 'api_v1/username2jwt/',
        data: values || formValue,
        actionType: ActionType().Login
      })
    } else {
      if (!isRadio) {
        setFormValue(values)
        setIsModal(true)
      }
    }
  };

  return (
    <div className={Styles.MLogin}>
      <div className={Styles.LoginCenter}>
        <div className={Styles.LoginLogo}>
          <img src={MiniLogo()} alt="Logo" />
        </div>
        <div style={{ textAlign: 'center', color: '#999' }}>
          <p>
            请使用你注册的电话号码或者电子邮箱Email作为用户名
          </p>
        </div>
        <div className={Styles.loginForm}>
          <Form
            // labelCol={{ span: 5 }}
            // wrapperCol={{ span: 19 }}
            name="normal_login"
            className={Styles.loginForms}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size='large'
          >
            <Form.Item
              name="username"
              className={Styles.loginInput}
              rules={[
                { required: true, message: "Using a phone number or email !" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Using a phone number or email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item className={Styles.loginButton}>
              <Button className={Styles.loginSumit} type="primary" htmlType="submit" loading={false}>
                Sign In
              </Button>
              <Button className={Styles.gotoRegister} type="link"><Link to='/Mregister'>Go to register</Link> </Button>
            </Form.Item>
            <div>
              <Radio checked={radioValue} style={{ fontSize: '12px', color: '#999' }} onClick={() => {
                setRadioValue(!radioValue)
                localStorage.setItem('isRadio', '不是第一次了')
              }}>
                登陆代表您以阅读并同意
                <Link>《数字信息》</Link> ,
                <Link> 《保密协议》</Link>
              </Radio>

            </div>


          </Form>
        </div>
      </div>
      <Modal
        title={null}
        footer={null}
        open={isModal}
        closeIcon={null}
        onCancel={() => {
          setIsModal(false)
        }}
        centered={true}
      >
        <div>
          请您认真阅读 <Link>《数字信息》</Link> 和 <Link> 《保密协议》</Link> 的全部条款，接受后可开始使用我的服务
        </div>
        <div className={Styles.loginModalFooter}>
          <Button type='default' onClick={() => setIsModal(false)}>不同意</Button>
          <Button type='primary' onClick={() => {
            setRadioValue(true)
            localStorage.setItem('isRadio', '不是第一次了')
            setIsModal(false)
            onFinish()
          }}>同意</Button>
        </div>
      </Modal>
    </div>
  )
}

export default MLogin