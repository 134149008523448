import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoter from './AppRouter';
import MRouter from './MPage/MRouter';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom';

import store from './Store'
import { IsPhone } from './Utils/Config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      {IsPhone() ? <MRouter /> : <AppRoter />}
      {/* <AppRoter /> */}
    </Router>
  </Provider>
);