import React from "react";

import Styles from './index.module.css'
import Utils from "../../Utils/Config";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

function PermissionPage() {
    const dispatch = useDispatch();
    return (
        <div className={Styles.PermissionPage}>
            <div>
                <div className={Styles.PermissionPageTitle}>
                    <img src={Utils.getLogo()} alt="logo" />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h2>NO Permission</h2>
                    <p>Sorry, Your permissions are not sufficient to access this webpage.</p>
                    <Button type='primary'>
                        <Link onClick={() => dispatch({ type: 'HomeKey', data: '/' })} to={'/'}>
                            Back Home
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PermissionPage