import { useState, useEffect } from 'react';

function CountdownTimer(props) {
  const { intervalTo } = props
  const [minutes, setMinutes] = useState(30);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval);
        clearInterval(intervalTo)
        // 倒计时结束时的处理
        alert('倒计时30分钟结束！');
      } else {
        if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  return (
    <span style={{ color: 'red' }}>
      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
    </span>
  );

}

export default CountdownTimer