import { useCallback, useEffect, useState, useTransition } from "react"
import Utils, { MiniLogo } from "../../../Utils/Config"
import ActionType from "../../../Store/actionType"
import { useDispatch, useSelector } from "react-redux"
import { Button, Carousel, Col, Drawer, Image, Input, Modal, Pagination, Row, Select, Space, Spin, message } from "antd"
import NoData from "../../../ComPonent/noData/noData"
import Styles from './index.module.css'

import QRCode from 'qrcode.react';
import chatPaySvg from './../../../Static/images/chatPay.svg'
import Vips from './../../../Static/images/vips.png'
import sao from './../../../Static/images/sao.svg'
import CountdownTimer from "./time"
import { Link, useNavigate } from "react-router-dom"
import { SwapOutlined, VideoCameraOutlined } from "@ant-design/icons"
import OpenVip from "../compoment/openVip"
const { Search } = Input;
let interval
let userInfo = JSON.parse(localStorage.getItem('userInfo'))
const isVip = userInfo?.vip_info?.expiring_time
const isVipTime = userInfo?.vip_info?.is_expire
const StudentStore = (props) => {
    const { setIsIndex } = props
    const [storePage, setStorePage] = useState(1)
    const [storePageSize, setStorePageSize] = useState(8)
    const [showBookPayModal, setShowBookPayModal] = useState(false)
    const [payBoxId, setPayBoxId] = useState(null)
    const [payLink, setPayLink] = useState(null)
    const [isTime, setIsTime] = useState(false);  //半个小时的倒计时
    const [gotoModal, setGotoModal] = useState(false)
    const [payState, setPayState] = useState(null)
    const [trialModal, SetTrialModal] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const [lansiValue, setLansiValue] = useState(null)
    const [searchType, setSearchType] = useState(1)
    const [boxDetails, setBoxDetails] = useState(null)
    const [videoModal, setVideoModal] = useState(null)
    const [messageVipModal, setMessageVipModal] = useState(false)
    const [isBoxAll, setIsBoxAll] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vipSetting = () => {
        Utils.get({
            url: 'api_v1/vip_setting/',
            actionType: ActionType().getVipSetting
        })
    }

    const vipSettingData = useSelector(data => data?.getVipSetting)

    // 商品
    const onGetStudentStore = useCallback(() => {
        let data = {
            page: storePage,
            page_size: storePageSize,
        }

        if (lansiValue) data['lexile'] = lansiValue
        let urls = 'api_v1/store/'

        if (searchValue) data['label_id'] = (searchValue)

        if (userInfo?.vip_info?.org_obj && isBoxAll) {
            data['org_fileter'] = 'only_org'
        }

        Utils.get({
            url: urls,
            params: data,
            actionType: ActionType().getStudentStore,
            Error: (res) => {
                console.log(res)
                if (res?.response?.data?.detail === "Invalid page.") {
                    setStorePage(1)
                }
            }
        })

    }, [storePage, storePageSize, searchValue, lansiValue, isBoxAll])
    // 活动
    // const onGetActivityList = useCallback(() => {
    //     Utils.get({
    //         url: 'api_v1/now_action/',
    //         params: {
    //             page: 1,
    //             page_size: 100000,
    //         },
    //         actionType: ActionType().getActivityList
    //     })
    // })
    // 标签
    const onGetShopingLabel = useCallback(() => {
        Utils.get({
            url: 'api_v1/choices/',
            params: {
                page: 1,
                page_size: 1000,
                where_field: searchType
            },
            actionType: ActionType().getShopingLabel
        })
    }, [searchType])

    useEffect(() => {
        vipSetting()

        onGetStudentStore()
        // onGetActivityList()
        onGetShopingLabel()
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        return () => clearInterval(interval);
    }, [onGetStudentStore, onGetShopingLabel])
    const onPaginationsTo = (page, pageSize) => {
        setStorePage(page)
        setStorePageSize(pageSize)
        // onGetStudentStore()
    }
    const studentStoreData = useSelector(data => data.getStudentStore)
    // const studentActivityData = useSelector(data => data?.getActivityList?.data?.results)
    const getShopingLabelData = useSelector(data => data.getShopingLabel)

    const onStoreBuy = (data) => {
        if (userInfo) {  // 登陆过的直接购买
            setPayBoxId(data.box_id)
            setBoxDetails(data)
            Utils.patch({
                url: 'api_v1/pay_qr_link/' + data.box_id + '/',
                data: {
                    wechatpay_type: 3
                },
                actionType: ActionType().patchStorePay,
                Success: (res) => {
                    setIsTime(true)
                    setPayLink(res.pay_link)
                    setShowBookPayModal(true)
                    interval = setInterval(() => {
                        Utils.get({
                            url: 'api_v1/pay_result/' + res.order_id + '/',
                            actionType: ActionType().getPayResult,
                            Success: (resPay) => {
                                if (resPay?.state > 1) {
                                    setPayState(resPay.state)
                                }
                            },
                            Error: () => {
                                setPayState(null)
                            }
                        })
                    }, 1000)
                }
            })
        } else {   // 没登陆的提示用户去登陆或者注册
            setGotoModal(true)
        }

    }
    // vip用户点击购买或试用
    const onPostAddBox = (boxId) => {
        if (!userInfo) {
            setGotoModal(true)
        } else {
            if (isVip) {  // 是VIP直接加入会员
                if (isVipTime) {  // 是否过期
                    message.warning('对不起您的会员已过期，请续费')
                    setMessageVipModal(true)
                } else {
                    if (boxId) Utils.post({
                        url: 'api_back/vip_user_add_box/',
                        data: {
                            box_obj: boxId
                        },
                        actionType: ActionType().post_vip_user_add_box,
                        Success: () => {
                            onGetStudentStore()
                        }
                    })
                }

            } else {  // 不是会员择需要提示去开通
                setMessageVipModal(true)
            }
        }


    }
    const storePayData = useSelector(data => data.patchStorePay)
    // 点击试用
    const storeTrial = (data) => {
        if (userInfo) {  // 登陆过的去详情
            setPayBoxId(data.box_id)

            Utils.post({
                url: 'api_v1/trial/',
                data: {
                    box_id: data.box_id
                },
                actionType: ActionType().postTrial,
                Success: () => {
                    SetTrialModal(true)
                }
            })
        } else {   // 没登陆的提示用户去登陆或者注册
            setGotoModal(true)
        }

    }
    const storeTrialData = useSelector(data => data.postTrial)
    const goToDetails = (data) => {
        if (userInfo) {  // 登陆过的去详情
            if (data.purchased[0]) {
                dispatch({ type: 'HomeKey', data: '/' })
                navigate('/')
            } else {
                navigate('/storeDetails/' + data.box_id)
            }
        } else {   // 没登陆的提示用户去登陆或者注册
            setGotoModal(true)
        }
    }

    const onPriceBox = (item) => {
        let priceInfo = item?.price_info
        if (priceInfo?.price_box === priceInfo?.price_action && priceInfo?.price_box === priceInfo?.price_coupon) return true
    }
    // 商品详情（使用视频）
    const onGetVideoData = (data) => {
        if (data) Utils.get({
            url: 'api_v1/store/' + data?.box_id + '/',
            actionType: ActionType().getStoreDetails,
        })
    }
    const storeDetailsData = useSelector(data => data?.getStoreDetails)


    return (
        <div className={Styles.StudentStore}>
            <div className={Styles.StudentStoreSearch}>
                <div style={{ minWidth: "350px" }}>
                    {
                        vipSettingData?.data?.vip_open === 'open' &&
                        <>
                            {/* 不是 会员 或者没有登录  */}
                            {
                                (!isVip || !userInfo) && < div className={Styles.openVip} >
                                    <div style={{ lineHeight: '1.3rem' }}>
                                        <div style={{ fontWeight: 600 }}>开通会员</div>

                                        <div style={{ color: '#e1251b', fontWeight: 600 }}>
                                            {'￥ ' + vipSettingData?.data?.price_info / 100}
                                        </div>
                                        <div>成为会员，免费读书( 读名著 )</div>
                                    </div>
                                    <OpenVip text={' 开通'} setIsIndex={(value) => setIsIndex(value)} />
                                </div>
                            }
                            {/* 是会员但是过期了 */}
                            {
                                (isVip && isVipTime) && < div className={Styles.openVip} >
                                    <div style={{ lineHeight: '1.3rem' }}>
                                        <div style={{ fontWeight: 600 }}>会员过期</div>
                                        <div style={{ color: '#e1251b', fontWeight: 600 }}>
                                            {'￥ ' + vipSettingData?.data?.price_info / 100}
                                        </div>
                                        <div>续费会员，继续免费读书( 读名著 )</div>
                                    </div>
                                    <OpenVip text={'立即续费'} setIsIndex={(value) => setIsIndex(value)} />
                                </div>
                            }
                        </>
                    }

                </div>

                {
                    userInfo?.vip_info?.org_obj && <div className={Styles.isBoxAll} onClick={() => setIsBoxAll(data => !data)}>
                        <div className={!isBoxAll && Styles.unBoxAll}>
                            全部
                        </div>
                        <SwapOutlined />
                        <div className={isBoxAll && Styles.unBoxAll}>
                            可购
                        </div>
                    </div>
                }

                <Space.Compact >
                    <Select
                        className={Styles.SeachSelectLeft}
                        value={searchType}
                        onChange={(value) => {
                            setSearchType(value)
                            setLansiValue(null)
                            setSearchValue(null)
                        }}
                        options={[
                            { value: 1, label: '蓝思值：' },
                            { value: 6, label: '国内/年级' },
                            { value: 7, label: '国际/美国年级' },
                            { value: 8, label: '标化考试', },
                        ]}
                    />
                    {
                        searchType === 1 && <Search
                            style={{ width: 250, }}
                            className={Styles.SeachInput}
                            placeholder="input search lansi"
                            allowClear
                            loading={false}
                            onChange={(value) => setLansiValue(value.target.value)}
                            onSearch={(value) => setLansiValue(value)}
                        />
                    }
                    {
                        searchType !== 1 && <Select
                            style={{ width: 250 }}
                            className={Styles.SeachSelectRight}
                            onChange={(value, selectedOptions) => setSearchValue(selectedOptions?.choice_id)}
                            options={getShopingLabelData?.data?.results}
                            allowClear
                            placeholder="Please enter the content you want to search for"
                        />
                    }
                </Space.Compact>
            </div>


            <Row gutter={30}>
                {
                    studentStoreData && studentStoreData.data && studentStoreData.data.results && (
                        studentStoreData.data.results.length === 0 ? <NoData /> : studentStoreData.data.results.map(
                            (item, key) => <Col span={12} key={key} className={Styles.StudentStoreCol} onClick={() => goToDetails({ ...item })}  >
                                {/* <Link to={item.purchased ? '/' : ('/storeDetails/' + item.box_id)}> */}
                                <Row gutter={16} className={Styles.StudentStoreRow}>
                                    <Col span={8} className={Styles.StudentStoreRowCol}>
                                        {
                                            item.annex && (
                                                item.annex.length === 0 ? <div className={Styles.StudentStoreListsTitle} style={{ backgroundImage: 'url(' + MiniLogo() + ')' }} /> : <Carousel dots={false} autoplay>
                                                    {
                                                        item.annex.map(
                                                            (img, imgkey) => <div
                                                                key={imgkey}
                                                                className={Styles.StudentStoreListsTitle}
                                                            >
                                                                <img src={Utils.returnFileUrl(img.file_obj_read)} alt="Img" />
                                                            </div>
                                                        )
                                                    }

                                                </Carousel>
                                            )
                                        }
                                    </Col>
                                    <Col span={16} className={Styles.StudentStoreRowCol2}>
                                        <div style={{ padding: '20px 0' }}>
                                            <div className={Styles.StudentStoreListsName}>
                                                {item.box_name || 'Book Name'}
                                            </div>
                                            <div className={Styles.StudentStoreListsSub}>
                                                介绍：{item.remark || 'Describe'}
                                            </div>
                                            <div className={Styles.StudentStoreListsSubVideo}>
                                                介绍：
                                                <span
                                                    style={{ color: '#1890ff' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setVideoModal(true)
                                                        onGetVideoData({ ...item })
                                                    }}
                                                >
                                                    视频 - <VideoCameraOutlined /> - Video
                                                </span>
                                            </div>
                                            <div className={Styles.StudentStoreListsSubSub}>
                                                {
                                                    (item?.lexile_start || item?.lexile_end) && <>
                                                        <span>
                                                            蓝思值：<span className={Styles.labelList}>{item?.lexile_start} - {item?.lexile_end}</span>
                                                        </span>
                                                        <span>
                                                            &nbsp; &nbsp; |  &nbsp; &nbsp;
                                                        </span>
                                                    </>
                                                }


                                                {
                                                    item?.labels?.length !== 0 && <>
                                                        <span>
                                                            标签：
                                                        </span>
                                                        <span>
                                                            {
                                                                item?.labels?.map(
                                                                    (lable, lableKey) => <span key={lableKey} className={Styles.labelList}>
                                                                        {lable?.label_obj_read}
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </>
                                                }

                                                {/* {item.remark || 'Describe'} */}
                                            </div>
                                            {
                                                item.allow_buy ? <>
                                                    <div style={{ lineHeight: '4rem' }} >
                                                        {/* 原价 */}
                                                        {/* {
                                                            onPriceBox(item) && <div style={{ color: 'red', fontSize: '28px', fontWeight: '600' }}>
                                                                ￥ {item?.price_info?.price_box / 100}
                                                            </div>
                                                        } */}
                                                        {
                                                            (item?.box_type === 1) ? <div style={{ color: 'red', fontSize: '28px', fontWeight: '600' }}>
                                                                ￥ {item?.price_info?.price_box / 100}
                                                            </div> : onPriceBox(item) && <div style={{ color: 'red', fontSize: '28px', fontWeight: '600' }}>
                                                                ￥ {item?.price_info?.price_box / 100}
                                                            </div>
                                                        }
                                                        {/* 打折价格 */}
                                                        {
                                                            (item?.box_type === 2) && (
                                                                item?.price_info?.price_box !== item?.price_info?.price_action && < div className={Styles.PriceButton}>
                                                                    <div className={Styles.ButtonLeft} style={{ textDecoration: 'line-through' }}>
                                                                        ￥
                                                                        {
                                                                            item?.price_info?.price_box / 100
                                                                        }
                                                                    </div>
                                                                    <div className={Styles.ButtonRight}  >
                                                                        ￥
                                                                        {
                                                                            item?.price_info?.price_action / 100
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }


                                                    </div>

                                                </> : <div style={{ height: '4rem' }} />
                                            }

                                            <div className={Styles.StudentStoreListsButton}>

                                                {
                                                    (
                                                        item?.purchased[0] ? (   // 已购买
                                                            <Button onClick={(e) => {
                                                                e.stopPropagation()
                                                                navigate('/')
                                                            }}
                                                            >
                                                                已购买-去学习
                                                            </Button>
                                                        ) : (    //未购买
                                                            <>
                                                                {
                                                                    item?.allow_buy ? (
                                                                        (!isVip || item?.box_type === 2 || isVipTime) && <Button
                                                                            onClick={(e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                onStoreBuy({ ...item })
                                                                            }}
                                                                            loading={payBoxId === item.box_id && storePayData && storePayData.loading}
                                                                            className={Styles.PayButton}

                                                                        >
                                                                            购买
                                                                            {
                                                                                vipSettingData?.data?.vip_gift === 'open' && item?.group_type === 1 && <>
                                                                                    &nbsp;- 送会员
                                                                                </>
                                                                            }
                                                                        </Button>
                                                                    ) : <Button
                                                                        style={{ background: "#f0f0f0", color: '#000', }}
                                                                    >
                                                                        近期上线
                                                                    </Button>
                                                                }
                                                                {
                                                                    item?.allow_buy && item?.box_type === 1 && <Button onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        e.preventDefault()
                                                                        onPostAddBox(item?.box_id)
                                                                    }} >
                                                                        <img src={Vips} alt="VIP" className={Styles.imgVips} />
                                                                        会员免费阅读
                                                                    </Button>
                                                                }

                                                                {
                                                                    item?.allow_trial && (  // 可以试用
                                                                        item?.purchased[1] ? <Button
                                                                            style={{ background: "#f0f0f0", color: '#000' }}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                        >
                                                                            去试用
                                                                        </Button>
                                                                            : (
                                                                                (!isVip || isVipTime) && <Button
                                                                                    loading={payBoxId === item.box_id && storeTrialData?.loading}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        e.stopPropagation()
                                                                                        storeTrial({ ...item })
                                                                                    }}
                                                                                    className={Styles.TrialButton}

                                                                                >
                                                                                    试用
                                                                                </Button>
                                                                            )
                                                                    )
                                                                }

                                                            </>


                                                            //     (userInfo && isVip) ? <Link to={'/'}  >
                                                            //         <Button onClick={(e) => {
                                                            //             e.stopPropagation()
                                                            //             e.preventDefault()
                                                            //             onPostAddBox(item?.box_id)
                                                            //         }} >
                                                            //             <img src={Vips} alt="VIP" className={Styles.imgVips} />
                                                            //             会员免费阅读
                                                            //         </Button>
                                                            //     </Link> : (
                                                            //         item?.allow_trial ? (  //  可以试用
                                                            //             item?.purchased[1] ? (   // 正在试用中
                                                            //                 <div>
                                                            //                     {
                                                            //                         item.allow_buy ? (
                                                            //                             <Button
                                                            //                                 onClick={(e) => {
                                                            //                                     e.preventDefault()
                                                            //                                     e.stopPropagation()
                                                            //                                     onStoreBuy({...item})
                                                            //                                 }}
                                                            //                                 loading={payBoxId === item.box_id && storePayData && storePayData.loading}
                                                            //                                 className={Styles.PayButton}
                                                            //                                 style={{ borderRadius: '40px 0 0 40px', width: '140px ' }}
                                                            //                             >
                                                            //                                 购买
                                                            //                             </Button>
                                                            //                         ) : <Button
                                                            //                             style={{ borderRadius: ' 40px 0 0 40px ', width: '140px', background: "#f0f0f0", color: '#000' }}
                                                            //                         >
                                                            //                             近期上线
                                                            //                         </Button>
                                                            //                     }
                                                            //                     {
                                                            //                         userInfo && <Link to={'/'}>
                                                            //                             <Button
                                                            //                                 style={{ borderRadius: ' 0 40px 40px 0 ', width: '140px', background: "#f0f0f0", color: '#000' }}
                                                            //                                 onClick={(e) => e.stopPropagation()}
                                                            //                             >
                                                            //                                 去试用
                                                            //                             </Button>
                                                            //                         </Link>
                                                            //                     }



                                                            //                 </div>
                                                            //             ) : (
                                                            //                 <div>
                                                            //                     {
                                                            //                         item.allow_buy ? (
                                                            //                             <Button
                                                            //                                 onClick={(e) => {
                                                            //                                     e.preventDefault()
                                                            //                                     e.stopPropagation()
                                                            //                                     onStoreBuy({ ...item })
                                                            //                                 }}
                                                            //                                 loading={payBoxId === item.box_id && storePayData && storePayData.loading}
                                                            //                                 className={Styles.PayButton}
                                                            //                                 style={{ borderRadius: '40px 0 0 40px', width: '140px ' }}
                                                            //                             >
                                                            //                                 购买
                                                            //                             </Button>
                                                            //                         ) : <Button
                                                            //                             style={{ borderRadius: '40px 0 0 40px', width: '140px', background: "#f0f0f0", color: '#000' }}
                                                            //                         >
                                                            //                             近期上线
                                                            //                         </Button>
                                                            //                     }


                                                            //                     <Button
                                                            //                         loading={payBoxId === item.box_id && storeTrialData?.loading}
                                                            //                         onClick={(e) => {
                                                            //                             e.preventDefault()
                                                            //                             e.stopPropagation()
                                                            //                             storeTrial({ ...item })
                                                            //                         }}
                                                            //                         className={Styles.TrialButton}
                                                            //                         style={{ borderRadius: ' 0 40px 40px 0 ', width: '140px' }}
                                                            //                     >
                                                            //                         试用
                                                            //                     </Button>
                                                            //                 </div>
                                                            //             )


                                                            //         ) : (
                                                            //             item.allow_buy ? (
                                                            //                 <Button
                                                            //                     onClick={(e) => {
                                                            //                         e.preventDefault()
                                                            //                         e.stopPropagation()
                                                            //                         onStoreBuy({ ...item })
                                                            //                     }}
                                                            //                     loading={payBoxId === item.box_id && storePayData && storePayData.loading}
                                                            //                 >
                                                            //                     购买
                                                            //                 </Button>
                                                            //             ) : <Button
                                                            //                 style={{ background: "#f0f0f0", color: '#000' }}
                                                            //             >
                                                            //                 近期上线
                                                            //             </Button>
                                                            //         )
                                                            //     )
                                                        )
                                                    )
                                                }

                                            </div>
                                        </div >
                                    </Col >
                                </Row >
                            </Col >
                        )
                    )
                }
            </Row >


            <div className={Styles.Pagination}>
                <Pagination
                    total={studentStoreData?.data?.count}
                    hideOnSinglePage
                    current={storePage}
                    pageSize={storePageSize}
                    onChange={(page, pageSize) => onPaginationsTo(page, pageSize)}
                />
            </div>
            {/* 微信支付 */}
            <Modal
                title={null}
                open={showBookPayModal}
                footer={null}
                onCancel={() => {
                    setShowBookPayModal(false)
                    clearInterval(interval)
                    if (payState === 2) onGetStudentStore()
                }}
                width={450}
            >
                <div className={Styles.PayTitle}>
                    <img src={chatPaySvg} alt="Pay" />
                    <div>
                        <div className={Styles.PayTitleTitle}>微信支付</div>
                        <div className={Styles.PayTitleSub}> WeChat Pay</div>
                    </div>
                </div>
                {
                    boxDetails && <div className={Styles.PaySubPaice}>
                        {
                            onPriceBox(boxDetails) && <>
                                ￥ {boxDetails?.price_info?.price_box / 100}
                            </>
                        }
                        {boxDetails?.price_info?.price_box !== boxDetails?.price_info?.price_coupon && <>￥ {boxDetails?.price_info?.price_coupon / 100}</>}
                        {boxDetails?.price_info?.price_coupon !== boxDetails?.price_info?.price_action && <>￥ {boxDetails?.price_info?.price_action / 100}</>}
                    </div>
                }

                <div className={Styles.PayCenater}>
                    {
                        payLink && <QRCode value={payLink} renderAs="canvas" size={200} />
                    }
                    { // 支付成功后蒙版
                        payState === 2 && <div className={Styles.PayCenaterMen} >
                            <img src={chatPaySvg} alt="Pay" />
                            <span>
                                恭喜您，支付成功！
                            </span>

                        </div>
                    }
                </div>
                {
                    payState !== 2 && <div style={{ textAlign: 'center', fontSize: '12px', }} >
                        请您在
                        {isTime && <CountdownTimer intervalTo={interval} />}
                        内完成支付，如果支付成功后，页面没提示，请手动刷新页面
                    </div>
                }


                <div className={Styles.PayFooter}>
                    <img src={sao} alt="QR Codes" />

                    <div>
                        <div className={Styles.PayFooterTitle}>使用微信扫描二维码进行支付</div>
                        <div className={Styles.PayFooterSub}>Use WeChat to scan QR codes for payment</div>
                    </div>
                </div>
            </Modal>

            {/* 提示登录  */}
            <Modal
                title={null}
                open={gotoModal}
                footer={null}
                onCancel={() => {
                    setGotoModal(false)

                }}
                width={450}
            >
                <h2>温馨提示！</h2>
                <p className={Styles.gotoModalP}>
                    对不起！您还未<span>登陆</span>或<span>注册</span>,
                    请先完成<span>登陆</span>或<span>注册</span>后再购买或试用！
                </p>
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Button type="link" onClick={() => setIsIndex('index')}>
                        去登陆
                    </Button>

                    <Button type="link" onClick={() => setIsIndex('index')}>
                        去注册
                    </Button>

                </div>
            </Modal>
            {/* 试用成功后的提示MOdal */}
            <Modal
                open={trialModal}
                title={null}
                footer={null}
                onCancel={() => {
                    SetTrialModal(false)
                    onGetStudentStore()
                }}
                closeIcon={null}
            >
                <h1 style={{ color: ' rgb(0, 200, 0)' }}>恭喜您，试用成功</h1>
                <p>
                    你可以前往
                    <Link to={'/'}>
                        <Button type='link' onClick={() => dispatch({ type: 'HomeKey', data: '/' })}>My practice</Button>
                    </Link>
                    去学习，也可以
                    <Button type='link' onClick={() => {
                        SetTrialModal(false)
                        onGetStudentStore()
                    }}
                    > 留在此页面
                    </Button>
                    继续购买或试用！
                </p>
            </Modal>
            <Drawer
                open={videoModal}
                onClose={() => setVideoModal(false)}
                placement='right'
                title='视频介绍'
                style={{ content: { background: "f0f0f0" } }}
            >
                <div className={Styles.BookVideo}>
                    <div className={Styles.BookVideoData}>
                        {
                            storeDetailsData && <Spin spinning={storeDetailsData?.loading}>
                                {
                                    storeDetailsData?.data?.book_1_annex_info?.length === 0 ? <NoData /> : storeDetailsData?.data?.book_1_annex_info?.map(
                                        (item, key) => <div className={Styles.bookVidoList} key={key}>
                                            <span className={Styles.bookVidoListSpan}>
                                                {item.book_name || '书'} -  {item.annex_name || '视频'}
                                            </span>
                                            <video controls controlsList="nodownload" src={Utils.returnFileUrl(item.cloud_obj_read)} />
                                        </div>
                                    )
                                }
                            </Spin>
                        }
                    </div>
                </div>

            </Drawer>

            <Modal
                title={null}
                footer={null}
                open={messageVipModal}
                onCancel={() => setMessageVipModal(false)}
            >
                {/* 是会员但是过期了 */}
                {
                    isVip && isVipTime && <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 600, fontSize: "1.2rem" }}>
                            温馨提示！
                        </div>
                        <div style={{ margin: '20px' }}>
                            对不起！您的会员已过期，不能免费阅读此书籍。
                        </div>
                        <div style={{ color: '#e1251b', fontWeight: 600, fontSize: '1.5rem', marginBottom: '20px' }}>
                            {'￥ ' + vipSettingData?.data?.price_info / 100}
                        </div>
                        <div onClick={() => setMessageVipModal(false)}>
                            <OpenVip text={'我要续费'} setIsIndex={(value) => setIsIndex(value)} />
                        </div>
                    </div>
                }
                {/* 不是会员 */}
                {
                    !isVip && <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 600, fontSize: "1.2rem" }}>
                            温馨提示！
                        </div>
                        <div style={{ margin: '20px' }}>
                            对不起！你还不是VIP会员，不能免费阅读此书籍。
                        </div>
                        <div style={{ color: '#e1251b', fontWeight: 600, fontSize: '1.5rem', marginBottom: '20px' }}>
                            {'￥ ' + vipSettingData?.data?.price_info / 100}
                        </div>
                        <div onClick={() => setMessageVipModal(false)}>
                            <OpenVip text={'开通会员'} setIsIndex={(value) => setIsIndex(value)} />
                        </div>
                    </div>
                }
            </Modal>
        </div >
    )
}

export default StudentStore