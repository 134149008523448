import React, {
    useEffect,
} from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Styles from './login.module.css'
import Utils from "../../Utils/Config";
import { Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import ActionType from "../../Store/actionType";
import FooterCom from './../../ComPonent/footer'
function LoginPage() {

    useEffect(() => {
        // getDemo()
    }, [])

    // 登录表单提交
    const onFinish = (values) => {
        // values['page'] = 'word'
        Utils.post({
            url: 'api_v1/username2jwt/',
            data: values,
            actionType: ActionType().Login
        })
    };

    return (
        <div>
            <div className={Styles.LoginPage}>
                <div className={Styles.LoginCenter}>
                    <div className={Styles.LoginLogo}>
                        <img src={Utils.getLogo()} alt="Logo" />
                    </div>
                    <div className={Styles.loginForm}>
                        <Form
                            // labelCol={{ span: 5 }}
                            // wrapperCol={{ span: 19 }}
                            name="normal_login"
                            className={Styles.loginForms}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            size='large'
                        >
                            <Form.Item
                                name="username"
                                className={Styles.loginInput}
                                rules={[
                                    { required: true, message: "Please input your Username!" },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Username"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: "Please input your Password!" },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <div className={Styles.loginButton}>
                                <Button className={Styles.loginSumit} type="primary" htmlType="submit" loading={false}>
                                    Sign In
                                </Button>
                                <Button className={Styles.gotoRegister} type="link"><Link to='/register'>Go to register</Link> </Button>
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
            <FooterCom />
        </div>

    )
}

export default LoginPage
