import React from "react";
import { Spin } from "antd";
const Loading = () => {
    return (
        <div
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                height: "100px",
                width: "200px",
                margin: "auto",
                textAlign: "center",
            }}
        >
            <Spin size="large" />
            <br />
            <p style={{ lineHeight: "2rem", fontSize: "1rem", color: "#000" }}>
                努力加载中 . . .
            </p>
        </div>
    );
};
export default Loading;