import React from 'react'
import Styles from './noData.module.css'
import Utils, { MiniLogo } from '../../Utils/Config'
class NoData extends React.Component {
  render() {
    let { data, type } = this.props
    if (!data) data = 'Data Not Found'
    return (
      <div className={Styles.NoDataPage}>
        <div className={type ? (Styles.noData, Styles.noDataSuccess) : Styles.noData}>
          <div className={Styles.noDataLogo}><img src={MiniLogo()} alt='logo' /></div>
          <div className={Styles.noDataText}>{data}</div>
        </div>
      </div>

    )
  }
}

export default NoData